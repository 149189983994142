import { useEffect, useState } from "react";
import VideoCreatorButtonSection from "./shared/VideoCreatorButtonSection";
import { useNavigate, useParams } from "react-router-dom";
import LazyLoadImg from "components/LazyLoadImg";
import CreatorHeaderSkeleton from "components/skeleton/CreatorHeaderSkeleton";
import CreatorService from "services/creators/CreatorService";
import BackButton from "components/shared/BackButton";
import AnimatedShareButton from "./shared/AnimatedShareButton";
import { Helmet } from "react-helmet-async";
import { RouteName } from "constant/routeNames";
import FollowCreators from "./shared/FollowCreators";
import { useSelector } from "react-redux";
import verify from "Assets/Images/verify.png";
import LoginModel from "../PayPerView/shared/LoginModal";
import ArtistService from "services/music/ArtistService";
import SubscribeCreator from "./shared/SubscribeCreator";
import FollowFollowingText from "pages/newProfilePage/shared/FollowFollowingText";
import BackdropImg from "../../Assets/Images/backdrop.png";

function VideoCreator({
  handleClick,
  isActive,
  setIsActive,
  setPlayerIsActive,
  anyPlayerActive,
}: any) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const BaseUrl = window.location.origin;
  const currentUrl = BaseUrl + window.location.pathname;
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [creatorData, setCreatorData] = useState<any>();
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  // console.log("currentUrl : ", currentUrl);
  // console.log(window.location.href);

  useEffect(() => {
    if (!anyPlayerActive) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  const stripHtmlTags = (str: any) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = str;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const cleanDescription = (description: any) => {
    return stripHtmlTags(
      description
        .replace(/&amp;/g, "&")
        .replace(/&rsquo;/g, "’")
        .replace(/&ldquo;/g, '"')
        .replace(/&rdquo;/g, '"')
        .replace(/&nbsp;/g, "")
    );
  };

  useEffect(() => {
    setIsLoading(true);
    const CleanedSlug = slug?.slice(1);
    if (slug) {
      CreatorService.getCreatorBySlug(CleanedSlug).then((res) => {
        setIsLoading(false);
        setCreatorData(res);
        // console.log(res);
        console.log(creatorData?.general_info?.type);
      });
    }
    // }, 1000);
  }, [slug]);

  const rawDescription = creatorData?.general_info?.description || "";

  const truncatedDescription =
    rawDescription.length > 200
      ? `${cleanDescription(rawDescription.slice(0, 200))}...`
      : cleanDescription(rawDescription);

  const fullDescription = cleanDescription(rawDescription);

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  return (
    <>
      <Helmet>
        <title>
          {creatorData?.general_info?.name
            ? `${creatorData?.general_info?.name} - AYOZAT`
            : "AYOZAT"}
        </title>
        <meta name="title" content={creatorData?.general_info?.title} />
        <meta
          name="description"
          content={creatorData?.general_info?.description}
        />
        <link
          rel="canonical"
          href={`${FE_URL}/@${creatorData?.general_info?.username}`}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={creatorData?.general_info?.title} />
        <meta
          name="twitter:description"
          content={creatorData?.general_info?.description}
        />
        <meta
          name="twitter:image"
          content={creatorData?.general_info?.thumbnail_url}
        />
        <meta
          name="twitter:url"
          content={`${FE_URL}/@${creatorData?.general_info?.username}`}
        />

        <meta property="og:title" content={creatorData?.general_info?.name} />
        <meta
          property="og:description"
          content={creatorData?.general_info?.description}
        />
        <meta
          property="og:image"
          content={creatorData?.general_info?.thumbnail_url}
        />
        <meta
          property="og:url"
          content={`${FE_URL}/@${creatorData?.general_info?.username}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        <div className="relative">
          <div className="w-full h-[500px] max-sm:w-[98%]">
            <div
              className="object-cover w-full h-[500px] overflow-hidden rounded-md max-sm:w-[100%]"
              style={{
                backgroundImage: `url(${
                  creatorData?.general_info?.type?.some(
                    (item: any) => item.name === "Creator"
                  )
                    ? creatorData?.general_info?.banner_url
                    : BackdropImg
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div className="absolute top-0 items-center w-full h-full bg-gradient-to-t from-black videohero-back ">
            <div className="mt-10 ml-8 max-sm:ml-2">
              <BackButton />
            </div>
            <div className="ml-8 text-white mt-15 max-sm:ml-3">
              <div className="w-full h-[100px] rounded-full">
                <div className="w-[120px] h-[120px]  rounded-full overflow-hidden">
                  <LazyLoadImg
                    imgSrc={creatorData?.general_info?.thumbnail_url}
                    imgAlt={"Artist"}
                    imgHeight={"120px"}
                    imgWidth={"120px"}
                    className="w-[120px] h-full drop-shadow-2xl object-cover overflow-hidden !rounded-full"
                    style={{
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                {!isLoading && (
                  <>
                    <div className="flex items-center mt-10 text-white r text-md max-sm:mt-3">
                      {creatorData?.general_info?.type?.map(
                        (type: any, i: number) => (
                          <div className="flex mr-1 " key={i}>
                            <p className="font-medium text-md text-slate-300 max-sm:text-[10px] ">
                              {type.name}
                              {i <
                                creatorData?.general_info?.type?.length - 1 &&
                                " | "}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                    <div className="flex items-center mt-3 text-white r text-md max-sm:mt-3 max-sm:block">
                      <div className="flex items-center">
                        <h1 className="text-3xl font-bold text-white max-sm:text-xl">
                          {creatorData?.general_info?.name}
                        </h1>
                        {creatorData?.general_info?.type?.some(
                          (item: any) => item.name === "Creator"
                        ) ? (
                          <div className="flex items-center justify-center">
                            <img
                              src={verify}
                              alt=""
                              className="mx-3 mt-0 place-self-center verify-blue-tik max-sm:mx-2"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {creatorData?.general_info?.type?.some(
                        (item: any) => item.name === "Creator"
                      ) && (
                        <>
                          <FollowFollowingText
                            count={creatorData?.general_info?.followers_count}
                            text="Follower"
                            likes={creatorData?.general_info?.likes}
                          />
                        </>
                      )}
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          creatorData?.general_info?.description?.length > 300
                            ? `${creatorData?.general_info?.description
                                ?.slice(0, 300)
                                .replace(/&amp;/g, "&")
                                .replace(/&rsquo;/g, "’")
                                .replace(/&ldquo;/g, '"')
                                .replace(/&rdquo;/g, '"')
                                .replace(/&nbsp;/g, "")}...`
                            : creatorData?.general_info?.description
                                ?.replace(/&amp;/g, "&")
                                .replace(/&rsquo;/g, "’")
                                .replace(/&ldquo;/g, '"')
                                .replace(/&rdquo;/g, '"')
                                .replace(/&nbsp;/g, "") || "",
                      }}
                      data-full-description={fullDescription}
                      className="creator-description mt-10 text-[14px]  font-medium w-[50%] max-sm:w-full max-md:w-[80%] max-lg:w-[60%] max-sm:mt-3 "
                    />

                    <div className="flex gap-5 mt-5 max-sm:gap-3">
                      {/* {creatorData?.general_info?.type?.some(
                        (types: any) => types.name === "Creator") && (
                        <div className="">
                          <FollowCreators
                            creatorId={creatorData?.general_info?.id}
                            user={loggedInUser}
                            creator={creatorData?.general_info}
                            setShowLoginModal={setShowLoginModal}
                          />
                        </div>
                      )} */}
                      {creatorData?.general_info?.type?.some(
                        (types: any) => types.name === "Creator"
                      ) && (
                        <div className="">
                          <SubscribeCreator
                            creatorId={creatorData?.general_info?.id}
                            user={loggedInUser}
                            creator={creatorData?.general_info}
                            setShowLoginModal={setShowLoginModal}
                          />
                        </div>
                      )}

                      <div className="">
                        <AnimatedShareButton shareUrl={currentUrl} />
                      </div>
                    </div>
                  </>
                )}
                {isLoading && <CreatorHeaderSkeleton />}
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <VideoCreatorButtonSection
            isLoading={isLoading}
            creatorData={creatorData}
            setIsLoading={setIsLoading}
          />
        </div>
        {showLoginModal ? (
          <LoginModel
            clickLogin={closeLoginModal}
            setShowLoginModal={setShowLoginModal}
            navigateUrl={window.location.pathname}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default VideoCreator;
